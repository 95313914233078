<div class="wrapper-md">
    <app-breadcrumbs [items]="[
                     { url: '/manipulations', text: 'manipulation_listTitle' },
                     {
                        url: '/manipulations/' + (isNew ? 'add' : report?.Id),
                        text: isNew ? 'manipulation_newTitle' : report ? isFinal ? 'manipulation_viewTitle' : 'manipulation_editTitle' : '...'
                     }]"></app-breadcrumbs>

    <header class="content-header">
        <h1>
            {{(isNew ? 'manipulation_newTitle' : report ? isFinal ? 'manipulation_viewTitle' : 'manipulation_editTitle' : '...') | translate}}
            <span class="badge" [class.text-bg-success]="isFinal" *ngIf="report">
                {{'manipulation_status' + report.Status | translate}}
            </span>
        </h1>
        <aside *ngIf="canPickStudent && !studentPickerOpened">
            <button type="button" class="btn btn-outline" (click)="toggleStudentPicker()">
                <i class="fi fi-rs-user"></i>
                {{'manipulation_btnPickStudent' | translate}}
            </button>
        </aside>
    </header>

    <form #form="ngForm" *ngIf="isReady">
        <app-alert level="danger" *ngIf="!studentPickerOpened && !report.Resident">{{'eServiceNotAvailable' | translate}}</app-alert>

        <app-person-picker (pick)="setStudent($event)" *ngIf="studentPickerOpened"></app-person-picker>

        <ng-container *ngIf="!studentPickerOpened && report.Resident">
            <section class="block">
                <div class="row">
                    <div class="data-value col-sm">
                        <label>{{'manipulation_lblNameSurname' | translate}}</label>
                        <div>{{report.Resident.Name}} {{report.Resident.Surname}}</div>
                    </div>

                    <div class="data-value col-sm" *ngIf="isFinal || residentInfo.length == 1">
                        <label>{{'manipulation_lblSpeciality' | translate}}</label>
                        <div>{{report.Resident.SpecialityName}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="data-value col-sm">
                        <label>{{'manipulation_lblStudyYear' | translate}}</label>
                        <div>{{report.Resident.StudyYear}}</div>
                    </div>

                    <div class="data-value col-sm" *ngIf="isFinal">
                        <label>{{'manipulation_lblSubmitDate' | translate}}</label>
                        <div>{{report.FileDate | appDate:'datetime'}}</div>
                    </div>
                </div>

                <div class="row" *ngIf="!isFinal && residentInfo.length > 1">
                    <div class="col-sm-6 form-field">
                        <label class="form-label">{{'manipulation_lblChangeSpeciality' | translate}}</label>
                        <app-select [(ngModel)]="specialityId"
                                    [options]="residentInfo"
                                    [valueProperty]="'SpecialityId'"
                                    [displayFn]="specialityDisplayFn"
                                    [required]="true"
                                    (selectionChange)="onSpecialityChange()"
                                    name="specialityId">
                        </app-select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-sm required">
                        <label class="form-label">{{'manipulation_lblFrom' | translate}}</label>
                        <app-date [(ngModel)]="report.From"
                                  name="dateFrom"
                                  [required]="true"
                                  [disabled]="isFinal"></app-date>
                    </div>

                    <div class="form-field col-sm required">
                        <label class="form-label">{{'manipulation_lblTo' | translate}}</label>
                        <app-date [(ngModel)]="report.To"
                                  name="dateTo"
                                  [required]="true"
                                  [disabled]="isFinal"></app-date>
                    </div>
                </div>

                <app-alert level="danger" *ngIf="!isDateRangeValid">
                    {{'manipulation_dateRangeError' | translate}}
                </app-alert>
            </section>

            <section class="block">
                <app-table [rows]="report.Manipulations">
                    <ng-template #headTemplate>
                        <tr>
                            <th class="hidden-sm-down">{{'manipulation_lblNameWorkplace' | translate}}</th>
                            <th colspan="2" class="hidden-md-up">{{'manipulation_lblCodeLevelCountNameWorkplace' | translate}}</th>
                            <th class="hidden-sm-down">{{'manipulation_lblCode' | translate}}</th>
                            <th class="hidden-sm-down">{{'manipulation_lblLevel' | translate}}</th>
                            <th class="hidden-sm-down">{{'manipulation_lblCount' | translate}}</th>
                            <th class="hidden-sm-down"></th>
                        </tr>
                    </ng-template>
                    <ng-template #rowTemplate let-row>
                        <tr>
                            <td>
                                <div class="text-top hidden-md-up">
                                    {{row.SpecialityManipulation?.Code}}
                                    /
                                    {{row.SpecialityManipulation?.SkillLevel.Code ?? row.OtherSpecialityManipulationSkillLevel.Code}}
                                    ({{row.Count}})
                                </div>
                                <div>{{getSpecialityManipulationName(row)}}</div>
                                <div class="text-sub">{{row.Workplace?.Name ?? row.OtherWorkplaceName}}</div>
                            </td>
                            <td class="hidden-sm-down">{{row.SpecialityManipulation?.Code}}</td>
                            <td class="hidden-sm-down">{{row.SpecialityManipulation?.SkillLevel.Code ?? row.OtherSpecialityManipulationSkillLevel.Code}}</td>
                            <td class="hidden-sm-down">{{row.Count}}</td>
                            <td>
                                <div class="table-actions">
                                    <button (click)="editManipulation(row)" type="button" class="btn btn-outline btn-icon"
                                            [ngbTooltip]="'edit' | translate" *ngIf="!isFinal">
                                        <i class="fi fi-rs-pencil"></i>
                                    </button>

                                    <button (click)="viewManipulation(row)" type="button" class="btn btn-outline btn-icon"
                                            [ngbTooltip]="'view' | translate" *ngIf="isFinal">
                                        <i class="fi fi-rs-eye"></i>
                                    </button>

                                    <button (click)="removeManipulation(row)" type="button" class="btn btn-outline btn-icon"
                                            [ngbTooltip]="'delete' | translate" *ngIf="!isFinal">
                                        <i class="fi fi-rs-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </app-table>

                <app-alert level="warning" *ngIf="!report.Resident.SpecialityId">{{'manipulation_mustSelectSpeciality' | translate}}</app-alert>

                <div class="block-actions" *ngIf="!isFinal">
                    <button class="btn btn-outline" type="button" (click)="addManipulation()" [disabled]="!report.Resident.SpecialityId">
                        <i class="fi fi-rs-plus"></i>
                        {{'add' | translate}}
                    </button>
                </div>
            </section>

            <div class="form-field">
                <label class="form-label">{{'manipulation_lblNotes' | translate}}</label>
                <textarea class="form-control" [(ngModel)]="report.Notes" name="notes" maxlength="4000" [disabled]="isFinal" rows="5"></textarea>
            </div>


            <div class="form-field">
                <label class="form-label">{{'manipulation_attachments' | translate}}</label>
                <div *ngFor="let a of attachments; let i = index" class="d-flex mb-2">
                    <div class="flex-grow-1">
                        <app-file [(ngModel)]="a.file"
                                  [accept]="attachmentExtensions"
                                  [maxSize]="attachmentMaxSize"
                                  name="attachment{{i}}"
                                  [immediate]="!a.id"
                                  [disabled]="isFinal"
                                  (fileChange)="onFileChange($event, a)"
                                  #attachmentInput="ngModel"></app-file>
                        <div class="invalid-feedback" *ngIf="attachmentInput.errors?.maxSize">{{attachmentInput.errors?.maxSize}}</div>
                        <div class="invalid-feedback" *ngIf="checkDuplicateFileName(a)">{{'manipulation_duplicateFileName' | translate}}</div>
                    </div>

                    <button class="btn btn-outline btn-icon align-self-end ms-3" *ngIf="a.id"
                            (click)="downloadAttachment(a)" [ngbTooltip]="'download' | translate">
                        <i class="fi fi-rs-download"></i>
                    </button>
                </div>

                <div *ngIf="!isFinal">
                    <button class="btn btn-outline" type="button" (click)="addAttachment()"
                            [disabled]="attachments.length >= attachmentLimit || (attachments.length > 0 && !attachments[attachments.length - 1].file)">
                        <i class="fi fi-rs-plus"></i>
                        {{'manipulation_btnAddFile' | translate}}
                    </button>
                </div>
            </div>

            <div class="form-actions">
                <span class="form-actions-group">
                    <button class="btn btn-secondary" type="button" (click)="downloadReport()" *ngIf="isFinal">
                        <i class="fi fi-rs-download"></i>
                        {{'manipulation_btnDownload' | translate}}
                    </button>

                    <button class="btn btn-outline-primary" type="button" (click)="submit()" *ngIf="report.Resident && !isFinal">
                        <i class="fi fi-rs-paper-plane"></i>
                        {{'manipulation_btnSubmit' | translate}}
                    </button>
                </span>

                <span class="form-actions-group">
                    <button class="btn btn-outline" type="button" [routerLink]="['/manipulations'] | localize">
                        {{'manipulation_btnClose' | translate}}
                    </button>

                    <button class="btn btn-primary" type="button" (click)="save()" *ngIf="report.Resident && !isFinal">
                        {{'manipulation_btnSave' | translate}}
                    </button>
                </span>
            </div>
        </ng-container>
    </form>
</div>
