import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Classifier } from '../../../models/Classifier';
import { ICourseEqualizationFile, ICourseEqualizationInner } from '../../../models/CourseEqualization';
import { AppService } from '../../../services/app.service';
import { CourseEqualizationService } from '../../../services/course-equalization.service';
import { ITableColumn } from '../../../shared/table/table.component';
import { util } from '../shared';

export interface IInnerCourseEditOptions {
    grades: Classifier[];
    defaultTheoryGradeId?: string;
    defaultPracticeGradeId?: string;
}

@Component({
    selector: 'app-course-equalization-inner',
    templateUrl: './course-equalization-inner.component.html',
    styleUrls: ['../course-equalization.component.scss']
})
export class CourseEqualizationInnerComponent {
    constructor(
        private app: AppService,
        private service: CourseEqualizationService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() set options(value: IInnerCourseEditOptions) {
        this._options = value;
        this.gradeOptions = value.grades || [];
    }
    
    @Input() set data(value: ICourseEqualizationInner) {
        this._data = value;
        this.model = JSON.parse(JSON.stringify(value));

        if (!this.model.TheoryGradeId) {
            this.model.TheoryGradeId = this.options?.defaultTheoryGradeId;
        }

        if (!this.model.PracticeGradeId) {
            this.model.PracticeGradeId = this.options?.defaultPracticeGradeId;
        }
    }

    get options() {
        return this._options;
    }

    get data() {
        return this._data;
    }

    readonly outerColumns: ITableColumn[] = [
        { label: 'courseEqualization_lblCourse' },
        { label: 'courseEqualization_lblWeeks' },
        { label: 'courseEqualization_lblEcts' },
        { label: 'courseEqualization_lblTheoryPractice' },
        { label: 'courseEqualization_lblAttachments' }
    ];

    readonly getAttachmentNameDisplay = util.getAttachmentNameDisplay;

    model: ICourseEqualizationInner = <ICourseEqualizationInner>{};
    gradeOptions: Classifier[] = [];

    private _options: IInnerCourseEditOptions;
    private _data: ICourseEqualizationInner = <ICourseEqualizationInner>{};

    ok() {
        this.model.TheoryGrade = this.gradeOptions.find(t => t.Id == this.model.TheoryGradeId)?.Value;
        this.model.PracticeGrade = this.gradeOptions.find(t => t.Id == this.model.PracticeGradeId)?.Value;

        this.activeModal.close({
            model: this.model
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }

    downloadAttachment(attachment: ICourseEqualizationFile) {
        util.downloadAttachment(this.app, this.service, attachment);
    }

    onFullCreditChange(checked: boolean) {
        if (checked) {
            this.model.AcquireEcts = 0;
            this.model.AcquireWeeks = 0;
        }
    }
}
