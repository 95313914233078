import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { IPlanSummaryLecturer, IPlanSummaryRotation } from '../../../models/PlanSummary';
import { AppService } from '../../../services/app.service';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { currentRequestParams } from '../plan-summary.component';

@Component({
    selector: 'app-plan-summary-add-lecturer',
    templateUrl: './plan-summary-add-lecturer.component.html'
})
export class PlanSummaryAddLecturerComponent {
    constructor(
        public app: AppService,
        private service: PlanSummaryService,
        private activeModal: NgbActiveModal
    ) { }

    @Input() rotation: IPlanSummaryRotation;

    selected: IPlanSummaryLecturer;
    lecturers: IPlanSummaryLecturer[] = [];
    isSaving: boolean;

    readonly displayFn = (item: IPlanSummaryLecturer) => item ? `${item.Name} ${item.Surname} (${item.Position})` : '';

    submit() {
        this.isSaving = true;

        this.app.addLoading(this.service.addLecturer(this.selected.Id, this.rotation.Id, currentRequestParams), 500)
            .pipe(finalize(() => this.isSaving = false)).subscribe(() => {
                this.activeModal.close();
            });
    }

    cancel() {
        this.activeModal.dismiss();
    }

    getLecturers(search: string) {
        if (search) {
            if (search.length > 2) {
                this.app.addLoading(this.service.getLecturers(search, currentRequestParams)).subscribe(data => {
                    this.lecturers = data;
                });
            }
        } else {
            this.lecturers = [];
        }
    }
}
