<app-alert level="danger" *ngIf="error">{{error}}</app-alert>

<section class="block" *ngIf="isLoaded">
    <header class="block-header">
        <h5>{{'planSummary_courses_schedule' | translate}}</h5>
        <aside>
            <input class="form-control"
                   (input)="filterScheduler($event)"
                   [placeholder]="'planSummary_lblFilter' | translate">
        </aside>
    </header>

    <bryntum-scheduler-pro #scheduler
                           [columns]="schedulerConfig.columns"
                           [features]="schedulerConfig.features"
                           [startDate]="schedulerConfig.startDate"
                           [endDate]="schedulerConfig.endDate"
                           [weekStartDay]="schedulerConfig.weekStartDay"
                           [readOnly]="schedulerConfig.readOnly"
                           [resources]="schedulerModel.resources"
                           [events]="schedulerModel.events"
                           [assignments]="schedulerModel.assignments"></bryntum-scheduler-pro>
</section>

<section class="block" *ngIf="isLoaded">
    <header class="block-header">
        <h5>{{'planSummary_courses_list' | translate}}</h5>
        <aside>
            <input class="form-control"
                   (input)="searchCourses($event)"
                   [placeholder]="'planSummary_lblSearch' | translate"
                   maxlength="50"
                   [disabled]="total < 1">
        </aside>
    </header>

    <app-alert level="info" *ngIf="total == 0">{{'planSummary_courses_empty' | translate}}</app-alert>

    <div *ngFor="let g of coursesByYear">
        <div class="text-top mb-2">
            <span (click)="util.toggle(g)" class="course-year-label">
                {{g.year}}{{'planSummary_yearPostfix' | translate}}
                <i class="fi fi-rs-angle-circle-{{util.isExpanded(g) ? 'down' : 'right'}}"></i>
            </span>
        </div>

        <app-table [columns]="columns" [rows]="g.courses" pageSize="20" sortBy="fullName" *ngIf="util.isExpanded(g)">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        <div>{{row.fullName}}</div>
                        <div class="text-sub hidden-lg-up">
                            <div class="hidden-md-up">
                                <label>{{'planSummary_courses_lblDates' | translate}}:</label>
                                {{row.DateFrom | appDate}} - {{row.DateTo | appDate}} ({{row.WeekCount}} {{'planSummary_weeksPostfix' | translate}})
                            </div>
                            <div>
                                <label>{{'planSummary_courses_lblLecturers' | translate}}:</label>
                                {{row.lecturerString}}
                            </div>
                            <div>
                                <label>{{'planSummary_courses_lblPlace' | translate}}:</label>
                                {{row.CompanyName}}
                            </div>
                            <div>
                                <label>{{'planSummary_courses_lblEcts' | translate}}:</label>
                                {{row.Ects}}
                            </div>
                        </div>
                    </td>
                    <td class="hidden-sm-down">
                        <div>{{row.DateFrom | appDate}} - {{row.DateTo | appDate}}</div>
                        <div class="text-sub">{{row.WeekCount}} {{'planSummary_weeksPostfix' | translate}}</div>
                    </td>
                    <td class="hidden-md-down">{{row.lecturerString}}</td>
                    <td class="hidden-md-down">{{row.CompanyName}}</td>
                    <td class="hidden-md-down">{{row.Ects}}</td>

                    <td>
                        <div class="table-actions">
                            <button type="button" class="btn btn-outline btn-icon" (click)="addLecturer(row)"
                                    [disabled]="!row.isActive"
                                    [ngbTooltip]="'planSummary_courses_addLecturer' | translate">
                                <i class="fi fi-rs-user-add"></i>
                            </button>

                            <button type="button" class="btn btn-outline btn-icon" (click)="removeLecturer(row)"
                                    [disabled]="!row.isActive || !row.Lecturers.length"
                                    [ngbTooltip]="'planSummary_courses_removeLecturer' | translate">
                                <i class="fi fi-rs-remove-user"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr class="grade-row" *ngIf="row.Grades.length">
                    <td colspan="9">
                        <div class="grade-list">
                            <table>
                                <tr *ngFor="let g of row.Grades">
                                    <td class="hidden-sm-down text-nowrap">
                                        <span class="text-sub">
                                            {{'planSummary_courses_gradeType' + (g.IsFinal ? 'Final' : 'Intermediate') | translate}}
                                        </span>
                                    </td>
                                    <td class="hidden-sm-down text-nowrap">{{g.Type}}: {{g.Value}}</td>
                                    <td class="hidden-sm-down text-nowrap">{{g.Operator || ''}} ({{g.Date | appDate}})</td>
                                    <td class="hidden-sm-down" style="width: 99%"></td>
                                    <td class="hidden-md-up">
                                        <div class="text-sub">
                                            {{'planSummary_courses_gradeType' + (g.IsFinal ? 'Final' : 'Intermediate') | translate}}
                                        </div>
                                        <div class="text-top">{{g.Type}}: {{g.Value}}</div>
                                        <div><small>{{g.Operator}} ({{g.Date | appDate}})</small></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </div>
</section>
