<ng-container *ngIf="isLoaded">
    <app-alert level="danger" *ngIf="isError">
        <ng-container *ngIf="!isEmptyAbsences && !isEmptyExtraCourses">{{'application_error' | translate}}</ng-container>
        <ng-container *ngIf="isEmptyAbsences || isEmptyExtraCourses">{{'eServiceNotAvailable' | translate}}</ng-container>
    </app-alert>

    <app-alert level="success" *ngIf="isSuccess">{{'application_success' | translate}}</app-alert>
    <app-alert class="danger" *ngIf="student.IsValid === false">{{'application_studentDataNotValid' | translate}}</app-alert>

    <ng-container *ngIf="!isError && !isSuccess">
        <form class="form" #form="ngForm">
            <div class="form-field" [class.required]="fields.addressee().required" *ngIf="fields.addressee().visible">
                <label class="form-label">{{fields.addressee().label | translate}}</label>
                <input class="form-control" [(ngModel)]="addressee" name="addressee" [required]="fields.addressee().required"
                        [maxlength]="fields.addressee().length" [disabled]="fields.addressee().disabled" />
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.topic().required" *ngIf="fields.topic().visible">
                    <label class="form-label">{{fields.topic().label | translate}}</label>
                    <app-select [(ngModel)]="topic" name="topic" [required]="fields.topic().required"
                                [options]="topicItems" [displayProperty]="'Value'">
                    </app-select>
                </div>

                <div class="form-field col-sm-6" [class.required]="fields.dateFrom().required" *ngIf="fields.dateFrom().visible">
                    <label class="form-label">{{fields.dateFrom().label | translate}}</label>
                    <app-date [(ngModel)]="dateFrom" name="dateFrom" [required]="fields.dateFrom().required" [max]="dateTo"></app-date>
                </div>

                <div class="form-field col-sm-6" [class.required]="fields.dateTo().required" *ngIf="fields.dateTo().visible">
                    <label class="form-label">{{fields.dateTo().label | translate}}</label>
                    <app-date [(ngModel)]="dateTo" name="dateTo" [required]="fields.dateTo().required" [min]="dateFrom"></app-date>
                </div>
            </div>

            <div class="row">
                <div *ngIf="fields.academicYear().visible" class="form-field col-sm-6" [class.required]="fields.academicYear().required">
                    <label class="form-label">{{fields.academicYear().label | translate}}</label>
                    <app-select [(ngModel)]="academicYear" name="academicYear" [required]="fields.academicYear().required"
                                [options]="academicYearItems">
                    </app-select>
                </div>

                <div *ngIf="fields.reason().visible" class="form-field col-sm-6" [class.required]="fields.reason().required">
                    <label class="form-label">{{fields.reason().label | translate}}</label>
                    <app-select [(ngModel)]="reason" name="reason" [required]="fields.reason().required"
                                [options]="reasonItems" [displayProperty]="'Value'">
                    </app-select>
                </div>
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.studyCourse().required" *ngIf="fields.studyCourse().visible">
                    <label class="form-label">{{fields.studyCourse().label | translate}}</label>
                    <app-select [(ngModel)]="studyCourse" name="studyCourse" [required]="fields.studyCourse().required"
                                [options]="studyCourseItems">
                    </app-select>
                </div>

                <div class="form-field col-sm-6" [class.required]="fields.appealCounter().required" *ngIf="fields.appealCounter().visible">
                    <label class="form-label">{{fields.appealCounter().label | translate}}</label>
                    <app-select [(ngModel)]="appealCounter" name="appealCounter" [required]="fields.appealCounter().required"
                                [options]="appealCounterItems">
                    </app-select>
                </div>
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.scholarship().required" *ngIf="fields.scholarship().visible">
                    <label class="form-label">{{fields.scholarship().label | translate}}</label>
                    <app-select [(ngModel)]="scholarship" name="scholarship" [required]="fields.scholarship().required"
                                [options]="scholarshipItems" [displayProperty]="'Value'">
                    </app-select>
                </div>
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.closingSheet().required" *ngIf="fields.closingSheet().visible">
                    <label class="form-label">{{fields.closingSheet().label | translate}}</label>
                    <input matInput [(ngModel)]="closingSheet" name="closingSheet" [required]="fields.closingSheet().required"
                           [maxlength]="fields.closingSheet().length" disabled />
                </div>
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.absenceType().required" *ngIf="fields.absenceType().visible">
                    <label class="form-label">{{fields.absenceType().label | translate}}</label>
                    <app-select [(ngModel)]="absenceType" name="absenceType" [required]="fields.absenceType().required"
                                [options]="absenceTypeItems" [displayProperty]="'Value'">
                    </app-select>
                </div>

                <div class="form-field col-sm-6" [class.required]="fields.documentNumber().required" *ngIf="fields.documentNumber().visible">
                    <label class="form-label">{{fields.documentNumber().label | translate}}</label>
                    <input class="form-control" [(ngModel)]="documentNumber" name="documentNumber" [required]="fields.documentNumber().required"
                           [maxlength]="fields.documentNumber().length" />
                </div>
            </div>

            <div *ngIf="fields.prolongsPeriod().visible" class="mb-3">
                <app-checkbox [(ngModel)]="prolongsPeriod" name="prolongsPeriod">{{fields.prolongsPeriod().label | translate}}</app-checkbox>
            </div>

            <div *ngIf="fields.personData().visible" class="person-data">
                <div class="row mt-3 mb-3" *ngFor="let g of personDataGroups">
                    <div *ngFor="let f of g.fields" class="form-field col-sm-6" [class.required]="f.required">
                        <label class="form-label">{{f.name}}</label>

                        <input *ngIf="f.type === 'Address'; else personNonAddressField"
                               class="form-control"
                               name="personDataValue-{{f.code}}"
                               [ngModel]="f.displayValue"
                               readonly
                               (click)="editPersonAddress(f)" />

                        <ng-template #personNonAddressField>
                            <app-date *ngIf="f.type == 'Date'"
                                      [(ngModel)]="f.displayValue"
                                      [required]="f.required"
                                      (dateChange)="onPersonDateChange(f, $event)"
                                      name="personDataValue-{{f.code}}"></app-date>

                            <input *ngIf="f.type != 'Date'"
                                   class="form-control"
                                   name="personDataValue-{{f.code}}"
                                   [(ngModel)]="f.value"
                                   [required]="f.required"
                                   [email]="f.type === 'Email'"
                                   [maxlength]="f.type === 'Phone' ? 8 : f.type === 'PersonCode' ? 12 : 50"
                                   [pattern]="f.type === 'Phone' ? '^[0-9]{8}$' : f.type === 'PersonCode' ? '^[0-9]{6}-[0-9]{5}$' : '.*'" />
                        </ng-template>
                    </div>
                </div>

                <ng-container *ngIf="validatePersonData() as errors">
                    <app-alert level="warning" *ngIf="errors.length">
                        <div *ngFor="let e of errors">{{e | translate}}</div>
                    </app-alert>
                </ng-container>
            </div>

            <div class="row">
                <div class="form-field col-sm-6" [class.required]="fields.courseRound().required" *ngIf="fields.courseRound().visible">
                    <label class="form-label">{{fields.courseRound().label | translate}}</label>
                    <app-select [(ngModel)]="courseRound" name="courseRound" [required]="fields.courseRound().required"
                                [options]="courseRoundItems" [displayProperty]="'Value'">
                    </app-select>
                </div>
            </div>

            <section class="block" *ngIf="fields.extraCourses().visible">
                <h5>{{fields.extraCourses().label | translate}}</h5>

                <app-table [rows]="extraCourses" [columns]="extraCourseColumns">
                    <ng-template #rowTemplate let-row>
                        <tr>
                            <td>
                                <div><strong>{{row.Code}}</strong></div>
                                <div class="hidden-lg-up">{{row.Name}}</div>
                                <div class="hidden-md-up text-mute">
                                    <span>{{row.Semester}}{{'application_lblExtraCourseSemesterPostfix' | translate}}, </span>
                                    <span>{{row.CreditPoints}}{{'application_lblExtraCourseCreditPointsPostfix' | translate}}</span>
                                </div>
                            </td>
                            <td class="hidden-md-down">{{row.Name}}</td>
                            <td class="hidden-sm-down">{{row.Semester}}</td>
                            <td class="hidden-sm-down">{{row.CreditPoints}}</td>
                            <td>
                                <div class="table-actions">
                                    <button type="button" class="btn btn-outline btn-icon" (click)="removeExtraCourse(row)"
                                            [ngbTooltip]="'delete' | translate">
                                        <i class="fi fi-rs-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </app-table>

                <div class="row">
                    <div class="form-field col-md-9">
                        <app-select [placeholder]="'application_lblSelectCourse' | translate"
                                    [(ngModel)]="selectedExtraCourse" name="selectedExtraCourse"
                                    [options]="extraCourseInfo"
                                    [displayFn]="extraCourseInfoDisplayFn">
                        </app-select>
                    </div>
                    <div class="form-field col-md-3">
                        <button class="btn btn-outline" (click)="addExtraCourse()" type="button" [disabled]="!selectedExtraCourse">
                            <i class="fi fi-rs-plus"></i>
                            {{'add' | translate}}
                        </button>
                    </div>
                </div>
            </section>

            <div class="row" *ngIf="fields.contractNumber().visible">
                <div class="form-field col-sm-6" [class.required]="fields.contractNumber().required">
                    <label class="form-label">{{fields.contractNumber().label | translate}}</label>
                    <app-select [(ngModel)]="contractNumber" name="contractNumber" [required]="fields.contractNumber().required"
                                [disabled]="contractNumberItems.length === 1" [options]="contractNumberItems">
                    </app-select>
                </div>
            </div>

            <div class="row" *ngIf="fields.latePayments().visible">
                <div class="col-sm-6" [class.required]="fields.latePayments().required">
                    <label class="form-label">{{fields.latePayments().label | translate}}</label>
                    <textarea class="form-control" [(ngModel)]="latePayments" name="latePayments" [maxlength]="fields.latePayments().length"
                              [required]="fields.latePayments().required" rows="5"></textarea>
                </div>
            </div>

            <section class="block-em" *ngIf="fields.latePaymentSchedule().visible">
                <h5>{{fields.latePaymentSchedule().label | translate}}</h5>

                <ng-container *ngFor="let n of latePaymentSchedule; let i = index">
                    <hr class="block-sep hidden-md-up mb-3" *ngIf="i > 0" />
                    <div class="row was-validated">
                        <div class="form-field col-sm-5 required">
                            <input class="form-control" [ngModel]="n.Amount" #latePaymentAmountInput
                                   (ngModelChange)="onAmountChange(latePaymentAmountInput, n, 'Amount')"
                                   name="latePaymentAmount-{{i}}" required autocomplete="off"
                                   [pattern]="getAmountErrorPattern(n, 'Amount')"
                                   [placeholder]="'application_lblPaymentAmount' | translate" />
                        </div>

                        <div class="form-field col-sm-5 col-9 required">
                            <app-date [(ngModel)]="n.Date" name="latePaymentDate-{{i}}" [placeholder]="'application_lblPaymentDate' | translate"
                                      [required]="true"></app-date>
                        </div>

                        <div class="form-field col-sm-2 col-3">
                            <button type="button" class="btn btn-outline btn-icon" (click)="removeLatePayment(n)"
                                    [ngbTooltip]="'delete' | translate" *ngIf="latePaymentSchedule.length > 1">
                                <i class="fi fi-rs-trash"></i>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="block-actions">
                    <button class="btn btn-outline" (click)="addLatePayment()" type="button">
                        <i class="fi fi-rs-plus"></i>
                        {{'add' | translate}}
                    </button>
                </div>
            </section>

            <div class="form-field" [class.required]="fields.comments().required" *ngIf="fields.comments().visible">
                <label class="form-label">{{fields.comments().label | translate}}</label>
                <textarea class="form-control" [(ngModel)]="comments" name="comments" [maxlength]="fields.comments().length"
                          [required]="fields.comments().required" rows="5"></textarea>
            </div>

            <div *ngIf="fields.bankAccount().visible">
                <h5>{{fields.bankAccount().label | translate}}</h5>

                <div class="row">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountIsForeign' | translate}}</label>
                        <app-select [(ngModel)]="bankAccount.isForeign"
                                    name="bankAccountIsForeign"
                                    [required]="true"
                                    [displayProperty]="'label'"
                                    [valueProperty]="'value'"
                                    [options]="[{ value: false, label: bankAccountInLatviaText }, { value: true, label: bankAccountOutOfLatviaText }]">
                        </app-select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountHolder' | translate}}</label>
                        <input class="form-control" [(ngModel)]="bankAccount.holder" name="bankAccountHolder" required maxlength="100" />
                    </div>
                </div>

                <div class="row" *ngIf="!bankAccount.isForeign">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountPersonCode' | translate}}</label>
                        <input class="form-control" [(ngModel)]="bankAccount.personCode" name="bankAccountPersonCode" required
                               maxlength="12" pattern="^\d{6}-\d{5}$" #bankAccountPersonCodeInput="ngModel" />
                        <div class="invalid-feedback" *ngIf="!bankAccountPersonCodeInput.valid">{{'application_personCodeError' | translate}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountNumber' | translate}}</label>
                        <input class="form-control" [(ngModel)]="bankAccount.number" name="bankAccountNumber" required maxlength="34" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountSwift' | translate}}</label>
                        <input class="form-control" [(ngModel)]="bankAccount.swift" name="bankAccountSwift" required
                               maxlength="11" pattern="^([A-Z0-9]{8}|[A-Z0-9]{11})$" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-field col-sm-6 required">
                        <label class="form-label">{{'application_lblBankAccountBank' | translate}}</label>
                        <input class="form-control" [(ngModel)]="bankAccount.bank" name="bankAccountBank" required maxlength="100" />
                    </div>
                </div>
            </div>

            <div class="mb-3" *ngIf="fields.personPhoto().visible">
                <div class="person-photo">
                    <img [src]="personPhoto" *ngIf="!personPhotoChange" />
                    <app-picture [accept]="personPhotoFileExtensions"
                                 [maxSize]="personPhotoFileMaxSize"
                                 [labels]="personPhotoLabels"
                                 (croppedImage)="changeNewProfilePhoto($event)"></app-picture>
                </div>
            </div>

            <div *ngIf="fields.attachments().visible">
                <div *ngFor="let a of attachments; let i = index" class="form-field">
                    <label class="form-label">{{'application_lblAttachment' | translate}}</label>
                    <app-file [(ngModel)]="a.file"
                              [accept]="attachmentExtensions"
                              [maxSize]="attachmentMaxSize"
                              name="attachment{{i}}"
                              (fileChange)="onFileChange($event, a)"></app-file>
                </div>

                <div class="mb-3">
                    <button class="btn btn-outline" type="button" (click)="addAttachment()" [disabled]="attachments.length >= attachmentLimit">
                        <i class="fi fi-rs-plus"></i>
                        {{'application_btnAddFile' | translate}}
                    </button>
                </div>

                <app-alert level="danger" *ngIf="showAttachmentRequired">{{'application_fileRequired' | translate}}</app-alert>
                <app-alert level="danger" *ngIf="attachments.length >= attachmentLimit">{{'application_fileLimitReached' | translate}}</app-alert>
            </div>

            <app-alert level="info" *ngIf="textAfter">
                <div [innerHTML]="textAfter | safeHtml"></div>
            </app-alert>

            <app-application-student [student]="student" [isSkillsApp]="item.Type == 'Skills'"></app-application-student>

            <div>
                <app-checkbox [(ngModel)]="agreed" name="agreed">{{'application_lblAgree' | translate}}</app-checkbox>
            </div>

            <div class="block-actions">
                <button class="btn btn-outline" type="button" (click)="cancel()">{{'application_btnCancel' | translate}}</button>
                <button class="btn btn-primary" type="button" (click)="submit()">{{'application_btnSubmit' | translate}}</button>
            </div>
        </form>
    </ng-container>
</ng-container>
