<a href="#" style="display: none"></a>

<div class="modal-header">
    <h4 class="modal-title">{{'courseEqualization_editCourseTitle' | translate}}</h4>
</div>

<div class="modal-body">
    <form #form="ngForm">
        <div class="data-value">
            <label>{{'courseEqualization_lblCourse' | translate}}</label>
            <div>
                {{model.Code}} {{model.Name}}
            </div>
        </div>

        <div class="mb-3">
            <app-checkbox name="isFullCredit" [(ngModel)]="model.IsFullCredit" (stateChange)="onFullCreditChange($event)">
                {{'courseEqualization_lblIsFullCredit' | translate}}
            </app-checkbox>
        </div>

        <div class="row">
            <div class="col-sm form-field required">
                <label class="form-label">{{'courseEqualization_lblAcquireWeeks' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.AcquireWeeks" name="acquireWeeks" required pattern="^[1-9][0-9]{0,2}$"
                       [disabled]="model.IsFullCredit" />
            </div>

            <div class="col-sm form-field required">
                <label class="form-label">{{'courseEqualization_lblAcquireEcts' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.AcquireEcts" name="acquireEcts" required pattern="^[1-9][0-9]{0,2}(\.[0-9][0-9]?)?$"
                       [disabled]="model.IsFullCredit" />
            </div>
        </div>

        <div class="row">
            <div class="col-sm form-field">
                <label class="form-label">{{'courseEqualization_lblTheory' | translate}}</label>
                <app-select [(ngModel)]="model.TheoryGradeId"
                            [options]="gradeOptions"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            name="theory">
                </app-select>
            </div>

            <div class="col-sm form-field required">
                <label class="form-label">{{'courseEqualization_lblPractice' | translate}}</label>
                <app-select [(ngModel)]="model.PracticeGradeId"
                            [options]="gradeOptions"
                            [valueProperty]="'Id'"
                            [displayProperty]="'Value'"
                            [required]="true"
                            name="practice">
                </app-select>
            </div>
        </div>
    </form>

    <div class="mt-3">
        <app-table [rows]="model.Courses" [columns]="outerColumns">
            <ng-template #rowTemplate let-row>
                <tr>
                    <td>
                        {{row.Name}}
                        <div class="text-mute" *ngIf="row.University">{{row.University}}</div>
                        <div class="text-mute" *ngIf="row.Programme">{{row.Programme}}</div>
                    </td>
                    <td>{{row.Weeks}}</td>
                    <td>{{row.Ects | number:'1.0-2':'en-US'}}</td>
                    <td>{{row.TheoryGrade}} - {{row.PracticeGrade}}</td>
                    <td>
                        <div *ngFor="let a of row.Attachments" class="attachment-row" [title]="a.FileName">
                            <a *ngIf="a.Id" href="#" (click)="$event.preventDefault(); downloadAttachment(a)">
                                {{getAttachmentNameDisplay(a)}}
                            </a>
                            <ng-container *ngIf="!a.Id">{{a.FileName}}</ng-container>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </app-table>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" (click)="cancel()">{{'dialog_cancel' | translate}}</button>

    <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!form.valid">
        {{'dialog_ok' | translate}}
    </button>
</div>
