import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IManipulationReportEditModel, IManipulationReportListItem, IManipulationReportModel, IManipulationResident, IManipulationSkillLevel, IManipulationWorkplace, ISpecialityManipulation } from '../models/Manipulation';

import { environment as ENV } from '../../environments/environment';
import { map } from "rxjs/operators";
import { Utils } from "../core/Utils";

/**
 * Manipulation service
 */
@Injectable({ providedIn: "root" })
export class ManipulationService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/manipulations` }

    get(email?: string): Observable<IManipulationReportListItem[]> {
        const params = email ? { email } : {};
        return this.http.get<IManipulationReportListItem[]>(this.apiUrl, { params });
    }

    getById(id: number): Observable<IManipulationReportModel> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IManipulationReportModel>(url);
    }

    getResident(email?: string): Observable<IManipulationResident[]> {
        const url = `${this.apiUrl}/resident`;
        const params = email ? { email } : {};
        return this.http.get<IManipulationResident[]>(url, { params });
    }

    getWorkplaces(filter: string): Observable<IManipulationWorkplace[]> {
        const url = `${this.apiUrl}/workplaces`;
        const params = filter ? { filter } : {};
        return this.http.get<IManipulationWorkplace[]>(url, { params });
    }

    getSkillLevels(): Observable<IManipulationSkillLevel[]> {
        const url = `${this.apiUrl}/skillLevels`;
        return this.http.get<IManipulationSkillLevel[]>(url);
    }

    getSpecialityManipulations(programId: string): Observable<ISpecialityManipulation[]> {
        const url = `${this.apiUrl}/specialityManipulations`;
        const params = programId ? { programId } : {};
        return this.http.get<ISpecialityManipulation[]>(url, { params });
    }

    create(model: IManipulationReportEditModel, email?: string): Observable<number> {
        const url = `${this.apiUrl}`;
        const params = email ? { email } : {};

        model.From = Utils.getInputDateString(model.From) as any;
        model.To = Utils.getInputDateString(model.To) as any;

        return this.http.post<number>(url, model, { params });
    }

    update(id: number, model: IManipulationReportEditModel): Observable<number> {
        const url = `${this.apiUrl}/${id}`;

        model.From = Utils.getInputDateString(model.From) as any;
        model.To = Utils.getInputDateString(model.To) as any;

        return this.http.put<number>(url, model);
    }

    submit(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/submit`;
        return this.http.post(url, null);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    getPdf(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/pdf`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    addAttachment(id: number, file: File): Observable<number> {
        const url = `${this.apiUrl}/${id}/attachments`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post<number>(url, form);
    }

    removeAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.delete(url).pipe(map(res => res));
    }

    getAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }
}
