import { Component, Input, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';

import { SchedulerProConfig } from '@bryntum/schedulerpro';

import { AppService } from '../../../services/app.service';

import { ITableColumn } from '../../../shared/table/table.component';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IPlanSummaryRotation } from '../../../models/PlanSummary';
import { Util } from '../util';
import { PlanSummaryService } from '../../../services/plan-summary.service';
import { store, currentRequestParams } from '../plan-summary.component';
import { Utils } from '../../../core/Utils';
import { AppDatePipe } from '../../../pipes/date.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanSummaryAddLecturerComponent } from '../add-lecturer/plan-summary-add-lecturer.component';
import { PlanSummaryRemoveLecturerComponent } from '../remove-lecturer/plan-summary-remove-lecturer.component';

interface IRotationRow extends IPlanSummaryRotation {
    fullName: string;
    lecturerString: string;
    dateString: string;
    gradeString: string;
    isActive: boolean;
}

interface ICoursesByYear {
    year: number;
    courses: IRotationRow[];
};

@Component({
    selector: 'app-plan-summary-courses',
    templateUrl: './plan-summary-courses.component.html',
    styleUrls: ['../plan-summary.component.scss']
})
export class PlanSummaryCoursesComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: PlanSummaryService,
        private appDate: AppDatePipe,
        private modal: NgbModal
    ) { }

    @Input() studyYear: number;

    coursesByYear: ICoursesByYear[] = [];
    total: number;
    error: string;
    isLoaded: boolean;

    readonly util = Util;

    readonly columns: ITableColumn[] = [
        { property: 'fullName', label: 'planSummary_courses_lblCourse', sorts: true },
        { property: 'DateFrom', label: 'planSummary_courses_lblDateFromTo', cssClass: 'hidden-sm-down', sorts: true, type: 'date' },
        { property: 'Lecturer', label: 'planSummary_courses_lblLecturers', cssClass: 'hidden-md-down', sorts: true },
        { property: 'CompanyName', label: 'planSummary_courses_lblPlace', cssClass: 'hidden-md-down', sorts: true },
        { property: 'Ects', label: 'planSummary_courses_lblEcts', cssClass: 'hidden-md-down', sorts: true, type: 'number' },
        { width: '1px' }
    ];

    readonly schedulerModel = {
        resources: [],
        events: [],
        assignments: []
    };

    readonly schedulerConfig: Partial<SchedulerProConfig> = {
        columns: [],
        features: {
            cellMenu: false,
            eventDrag: false,
            eventCopyPaste: false,
            eventEdit: false,
            eventMenu: false,
            eventResize: false,
            taskEdit: false,
            dependencies: false,
            scheduleMenu: false,
            eventTooltip: {
                template: data => {
                    const rec: IPlanSummaryRotation = data.eventRecord['originalData'].data;

                    const df = this.appDate.transform(rec.DateFrom);
                    const dt = this.appDate.transform(rec.DateTo);
                    const lecturers = (rec.Lecturers || []).map(t => t.Name + ' ' + t.Surname).join(', ');

                    return `<div><small>${rec.Code}</small></div>`
                        + `<div class="mb-2">${rec.Name}</div>`
                        + `<div>${df} - ${dt}</div>`
                        + `<div class="mb-2">${rec.WeekCount} ${this.app.translate('planSummary_weeksPostfix')}</div>`
                        + `<div><i class="fi fi-rs-user"></i> ${lecturers}</div>`
                        + `<div><i class="fi fi-rs-marker"></i> ${rec.CompanyName || ''}</div>`;
                }
            }
        },
        autoHeight: true,
        readOnly: true,
        weekStartDay: 1
    };

    private courses: IPlanSummaryRotation[] = [];
    private allCoursesByYear: ICoursesByYear[] = [];

    private readonly eventColors = ['#669F2A', '#15B79E', '#0BA5EC', '#2970FF', '#6172F3', '#7A5AF8', '#D444F1', '#F63D68', '#EF6820', '#EAAA08'];
    private readonly courseSearchSubj = new Subject<string>();
    private readonly schedulerFilterSubj = new Subject<string>();

    ngOnInit() {
        this.courseSearchSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            if (!term) {
                this.coursesByYear = [...this.allCoursesByYear];
            } else {
                const search = term.toLowerCase();
                const byYear: ICoursesByYear[] = [];

                this.allCoursesByYear.forEach(t => {
                    const year = { ...t };

                    year.courses = t.courses.filter(c => {
                        return c.fullName?.toLowerCase().includes(search)
                            || c.Ects?.toString().includes(search)
                            || c.dateString?.toLowerCase().includes(search)
                            || c.WeekCount?.toString().includes(search)
                            || c.gradeString.toLowerCase().includes(search)
                            || c.lecturerString.toLowerCase().includes(search)
                            || c.CompanyName?.toLowerCase().includes(search);
                    });

                    if (year.courses.length) {
                        this.util.toggle(year);
                        byYear.push(year);
                    }
                });

                this.coursesByYear = byYear;
            }
        });

        this.schedulerFilterSubj.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
            this.buildScheduler(this.courses, term);
        });

        this.load();
    }

    prettifyString(str: string): string {
        return (str || '').replace(';', '; ');
    }

    searchCourses(event) {
        this.courseSearchSubj.next(event.target.value);
    }

    filterScheduler(event) {
        this.schedulerFilterSubj.next(event.target.value);
    }

    addLecturer(row: IRotationRow) {
        const ref = this.modal.open(PlanSummaryAddLecturerComponent);
        ref.componentInstance.rotation = row;
        ref.result.then(() => {
            this.app.notify(this.app.translate('planSummary_addLecturer_success'));
            this.load(true);
        }, () => { });
    }

    removeLecturer(row: IRotationRow) {
        const ref = this.modal.open(PlanSummaryRemoveLecturerComponent);
        ref.componentInstance.rotation = row;
        ref.result.then(() => {
            this.app.notify(this.app.translate('planSummary_removeLecturer_success'));
            this.load(true);
        }, () => { });
    }

    private load(force: boolean = false) {
        if (force) {
            store.rotations = undefined;
        }

        this.getRotations().subscribe(data => {
            this.courses = data;

            this.buildScheduler(data);
            this.buildTable(data);

            this.isLoaded = true;
        });
    }

    private getRotations() {
        if (store.rotations) {
            return of(store.rotations);
        } else {
            return this.app.addLoading(this.service.getRotations(currentRequestParams)).pipe(tap(data => {
                store.rotations = data;
            }));
        }
    }

    private buildScheduler(data: IPlanSummaryRotation[], filter?: string) {
        let minDate: Date;
        let maxDate: Date;

        const events = [];
        const assignments = [];

        filter = (filter || '').toLowerCase();

        let currentColorIndex = 0;

        data.forEach(t => {
            const name = `${t.Code} ${t.Name}`;

            if (!filter || name.toLowerCase().includes(filter)) {
                const bd = Utils.ensureDate(t.DateFrom);
                const ad = Utils.ensureDate(t.DateTo);

                if (!minDate || bd < minDate) {
                    minDate = bd;
                }

                if (!maxDate || ad > maxDate) {
                    maxDate = ad;
                }

                events.push({
                    id: t.Id,
                    startDate: bd,
                    endDate: ad,
                    name: name,
                    data: t,
                    eventColor: this.eventColors[currentColorIndex]
                });

                assignments.push({
                    event: t.Id,
                    resource: 1
                });

                currentColorIndex++;

                if (currentColorIndex == this.eventColors.length) {
                    currentColorIndex = 0;
                }
            }
        });

        this.schedulerConfig.startDate = minDate;
        this.schedulerConfig.endDate = maxDate;

        this.schedulerModel.resources = [{
            id: 1,
            name: `${store.resident?.Name} ${store.resident?.Surname}`
        }];
        this.schedulerModel.assignments = assignments;
        this.schedulerModel.events = events;
    }

    private buildTable(data: IPlanSummaryRotation[]) {
        this.allCoursesByYear = [];

        const now = new Date();

        data.forEach(t => {
            let g = this.allCoursesByYear.find(g => g.year == t.StudyYear);

            if (!g) {
                g = { year: t.StudyYear, courses: [] };
                this.allCoursesByYear.push(g);
            }

            t.DateFrom = Utils.ensureDate(t.DateFrom);
            t.DateTo = Utils.ensureDate(t.DateTo);

            const row: IRotationRow = {
                ...t,
                fullName: `${t.Name} (${t.Code})`,
                lecturerString: t.Lecturers.map(n => `${n.Name} ${n.Surname}`).join(', '),
                gradeString: t.Grades.map(n => `${n.Type}: ${n.Value}`).join(', '),
                dateString: `${this.appDate.transform(t.DateFrom)} - ${this.appDate.transform(t.DateTo)}`,
                isActive: t.DateFrom <= now && t.DateTo >= now
            }

            g.courses.push(row);
        });

        this.coursesByYear = [...this.allCoursesByYear];

        if (this.coursesByYear.length) {
            const currentYear = this.coursesByYear.find(t => t.year == this.studyYear);
            this.util.toggle(currentYear || this.coursesByYear[0]);
        }

        this.total = this.allCoursesByYear.length;
    }
}
