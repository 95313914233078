import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { of, forkJoin, Subject } from 'rxjs';
import { ICanDeactivateGuard } from '../../core/CanDeactivateGuard';

import { IPersonSearchResultItem } from '../../models/Person';
import { ManipulationEditComponent } from './edit.component';

import * as FileSaver from 'file-saver';

import { AppService } from '../../services/app.service';
import { ManipulationService } from '../../services/manipulation.service';
import { AppDatePipe } from '../../pipes/date.pipe';
import { Utils } from '../../core/Utils';
import { store } from './store';
import {
    IManipulationEditModel, IManipulationModel, IManipulationReportEditModel, IManipulationReportModel,
    IManipulationResident, IManipulationSkillLevel, IManipulationWorkplace, ISpecialityManipulation, ManipulationReportStatus
} from '../../models/Manipulation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../services/parameter.service';
import { catchError, defaultIfEmpty, tap } from 'rxjs/operators';
import { createFileStub } from '../../shared/file/file.component';

export const userRights = {
    setStudent: 'MANIPULATION.SET_STUDENT'
};

interface IAttachment {
    id?: number;
    file?: File;
}

@Component({
    selector: 'app-manipulation-form',
    templateUrl: './form.component.html',
    styleUrls: ['./shared.css']
})
export class ManipulationFormComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        private app: AppService,
        private service: ManipulationService,
        private parameters: ParameterService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private appDate: AppDatePipe
    ) { }

    report: IManipulationReportModel;
    specialityId: string;
    workplaces: IManipulationWorkplace[] = [];
    skillLevels: IManipulationSkillLevel[] = [];
    specialityManipulations: ISpecialityManipulation[] = [];
    residentInfo: IManipulationResident[] = [];

    isNew: boolean;
    isReady: boolean;
    canDownload: boolean;
    canPickStudent: boolean;
    studentPickerOpened: boolean;
    student: IPersonSearchResultItem;

    attachments: IAttachment[] = [];
    attachmentExtensions: string = '';
    attachmentMaxSize: number = 0;
    attachmentLimit: number = 0;

    readonly specialityDisplayFn = (option: IManipulationResident) => `${option.SpecialityCode} - ${option.SpecialityName}`;

    get isFinal(): boolean {
        return this.report?.Status == ManipulationReportStatus.Submitted;
    }

    get isDateRangeValid(): boolean {
        const from = Utils.getInputDate(this.report.From);
        const to = Utils.getInputDate(this.report.To);

        if (!from || !to) return true;

        if (from >= to) return false;

        return true;
    }

    private originalSpecialityId: string;
    private originalFrom?: Date;
    private originalTo?: Date;
    private originalNotes: string;
    private originalManipulations: string = '[]';
    private isSaved: boolean;

    @ViewChild('form', { static: false }) private form: NgForm;

    ngOnInit() {
        const user = this.app.currentUser;

        if (user) {
            this.canPickStudent = user.rights.indexOf(userRights.setStudent) > -1;
        }

        const specId = this.route.snapshot.queryParams['specialityId'];
        const email = this.route.snapshot.queryParams['email'];

        this.student = store.getStudent();

        this.route.params.subscribe(para => {
            let id = para['id'];

            if (!id) {
                this.isNew = true;
                this.report = <IManipulationReportModel>{
                    Status: ManipulationReportStatus.Draft,
                    Manipulations: [],
                    Attachments: []
                };

                if (this.canPickStudent) {
                    this.isReady = true;

                    if (this.student) {
                        this.loadResident(undefined, this.student.Email);
                    } else if (email) {
                        this.student = undefined;
                        this.loadResident(undefined, email);
                    } else {
                        this.toggleStudentPicker();
                    }
                } else {
                    this.loadResident(specId, email);
                }
            } else {
                this.loadById(+id);
            }
        });

        this.parameters.getValues().subscribe(data => {
            this.attachmentMaxSize = this.parameters.findValue(data, 'ResidencyReportFileMaxSize', t => +t, 0);
            this.attachmentLimit = this.parameters.findValue(data, 'ResidencyReportFileLimit', t => +t, 0);
            this.attachmentExtensions = this.parameters.findValue(data, 'ResidencyReportFileExtensions',
                t => '.' + t.split(',').join(',.'), '');
        });
    }

    canDeactivate(): Observable<boolean> {
        if (!this.isSaved && !this.isFinal && this.hasChanges()) {
            const subj = new Subject<boolean>();

            this.app.confirm(this.app.translate('manipulation_confirmUnsaved'), result => {
                subj.next(result);
            });

            return subj.asObservable();
        }

        return of(true);
    }

    toggleStudentPicker() {
        if (this.studentPickerOpened) {
            this.studentPickerOpened = false;
        } else {
            this.canDeactivate().subscribe(res => {
                if (res) {
                    this.studentPickerOpened = true;
                    this.report.Resident = undefined;
                }
            });
        }
    }

    addManipulation() {
        const model = <IManipulationModel>{};

        this.app.addLoading(forkJoin([this.loadSkillLevels(), this.loadSpecialityManipulations()])).subscribe({
            next: result => {
                this.skillLevels = result[0];
                this.specialityManipulations = result[1];

                this.sortSpecialityManipulations();

                const ref = this.modal.open(ManipulationEditComponent, { size: 'lg' });
                ref.componentInstance.data = {
                    model: model,
                    skillLevels: result[0],
                    specialityManipulations: result[1],
                    residentWorkplaceId: this.report.Resident.WorkplaceId,
                    residentWorkplaceName: this.report.Resident.WorkplaceName,
                    isFinal: this.isFinal
                };

                ref.closed.subscribe(() => {
                    this.report.Manipulations.push(model);
                });
            }
        });
    }

    onDateFocus(date: Date, picker: { open: () => void }) {
        if (!date) {
            picker.open();
        }
    }

    setStudent(student: IPersonSearchResultItem) {
        this.studentPickerOpened = false;
        this.student = student;
        this.loadResident(undefined, student.Email);

        store.setStudent(student);
    }

    onSpecialityChange() {
        if (this.report.Resident.SpecialityId != this.specialityId && this.report.Resident.SpecialityId && this.report.Manipulations?.length) {
            this.app.confirm(this.app.translate('manipulation_confirmSpecialityChange'), result => {
                if (result) {
                    this.report.Manipulations = [];
                    this.specialityManipulations = [];

                    let resident = this.residentInfo.find(t => t.SpecialityId == this.specialityId);

                    if (resident)
                        this.report.Resident = resident;
                } else {
                    this.form.controls.specialityId.setValue(this.report.Resident.SpecialityId);
                }
            });
        } else {
            let resident = this.residentInfo.find(t => t.SpecialityId == this.specialityId);

            if (resident)
                this.report.Resident = resident;
        }
    }

    editManipulation(manipulation: IManipulationModel) {
        forkJoin([this.loadSkillLevels(), this.loadSpecialityManipulations()]).subscribe({
            next: result => {
                this.skillLevels = result[0];
                this.specialityManipulations = result[1];

                this.sortSpecialityManipulations();

                const ref = this.modal.open(ManipulationEditComponent, { size: 'md' });
                ref.componentInstance.data = {
                    model: manipulation,
                    skillLevels: result[0],
                    specialityManipulations: result[1],
                    isFinal: this.isFinal
                };

                ref.closed.subscribe(() => {
                    //if (ok) {
                    // Sort if needed!
                    //}
                });
            }
        });
    }

    viewManipulation(manipulation: IManipulationModel) {
        const ref = this.modal.open(ManipulationEditComponent, { size: 'md' });
        ref.componentInstance.data = {
            model: manipulation,
            workplaces: manipulation.OtherWorkplace ? [] : [manipulation.Workplace],
            skillLevels: manipulation.OtherSpecialityManipulation ? [manipulation.OtherSpecialityManipulationSkillLevel] : [],
            specialityManipulations: manipulation.OtherSpecialityManipulation ? [] : [manipulation.SpecialityManipulation],
            isFinal: this.isFinal
        };
    }

    removeManipulation(manipulation: IManipulationModel) {
        const ix = this.report.Manipulations.indexOf(manipulation);

        if (ix > -1) {
            this.report.Manipulations.splice(ix, 1);
        }
    }

    save() {
        if (!this.validate()) return;

        this.createOrUpdate().subscribe(id => {
            this.isSaved = true;
            this.app.notify(this.app.translate('manipulation_saved'));
            this.app.navigate(['/manipulations']);
        });
    }

    submit() {
        if (!this.validate()) return;

        const body = this.app.translate('manipulation_submitText')
            .replace('{from}', this.appDate.transform(this.report.From))
            .replace('{to}', this.appDate.transform(this.report.To));

        this.app.confirm({
            text: body,
            title: this.app.translate('manipulation_submitTitle'),
            okText: this.app.translate('manipulation_submitOk')
        }, result => {
            if (!result) return;

            this.app.addLoading(this.createOrUpdate()).subscribe(id => {
                this.service.submit(id).subscribe(() => {
                    this.isSaved = true;
                    this.app.notify(this.app.translate('manipulation_submitted'));
                    this.app.navigate(['/manipulations']);
                });
            });
        });
    }

    downloadReport() {
        this.app.addLoading(this.service.getPdf(this.report.Id)).subscribe(result => {
            FileSaver.saveAs(result, 'atskaite.pdf');
        });
    }

    downloadAttachment(attachment: IAttachment) {
        this.app.addLoading(this.service.getAttachment(attachment.id)).subscribe(result => {
            FileSaver.saveAs(result, attachment.file.name);
        });
    }

    addAttachment() {
        this.attachments.push(<IAttachment>{});
    }

    removeAttachment(file: IAttachment) {
        let i = this.attachments.indexOf(file);

        if (i > -1)
            this.attachments.splice(i, 1);
    }

    onFileChange(event: File, file: IAttachment) {
        if (!event) {
            this.removeAttachment(file);
        }
    }

    checkDuplicateFileName(file: IAttachment): boolean {
        if (!file.file) return false;
        return this.attachments.filter(t => t.file?.name == file.file.name).length > 1;
    }

    getSpecialityManipulationName(manipulation: IManipulationModel): string {
        if (manipulation.OtherSpecialityManipulation)
            return manipulation.OtherSpecialityManipulationName;

        if (this.app.currentLanguage.toLowerCase() != 'en')
            return manipulation.SpecialityManipulation?.Name;
        else
            return manipulation.SpecialityManipulation?.NameEn;
    }

    private createOrUpdate() {
        const subj = new Subject<number>();
        const model: IManipulationReportEditModel = this.getManipulationReportEditModel(this.report);

        const saveAttachments = (id: number) => {
            this.app.addLoading(this.saveAttachments(id)).subscribe(files => {
                const errors = files.filter(f => f.error).map(f => {
                    return `<p><strong>${f.fileName}</strong><br>${f.error}</p>`;
                });

                if (errors.length) {
                    this.app.showError(errors.join(''));
                } else {
                    subj.next(id);
                }
            });
        };

        if (this.isNew) {
            this.app.addLoading(this.service.create(model, this.student?.Email)).subscribe(id => {
                saveAttachments(id);
            });
        } else {
            this.app.addLoading(this.service.update(this.report.Id, model)).subscribe(() => {
                saveAttachments(this.report.Id);
            });
        }

        return subj.asObservable();
    }

    private saveAttachments(id: number) {
        const results: {
            fileName: string,
            added?: boolean,
            removed?: boolean,
            error?: string
        }[] = [];

        const subj = new Subject<typeof results>();

        const remove = (this.report.Attachments || []).filter(t => !this.attachments.some(a => a.file.name == t.FileName)).map(t => {
            results.push({ fileName: t.FileName });

            const entry = results[results.length - 1];

            return this.service.removeAttachment(t.Id).pipe(tap(() => {
                entry.removed = true;
            }), catchError(err => {
                entry.removed = false;
                entry.error = this.app.getHttpResponseError(err);

                return of(null);
            }));
        });

        const add = this.attachments.filter(t => !!t.file.size).map(t => {
            results.push({ fileName: t.file.name });

            const entry = results[results.length - 1];

            return this.service.addAttachment(id, t.file).pipe(tap(() => {
                entry.added = true;

                // drop the file to prevent from re-upload if saving again after error
                t.file = createFileStub(t.file.name);
            }), catchError(err => {
                entry.added = false;
                entry.error = this.app.getHttpResponseError(err);

                return of(null);
            }));
        });

        if (!remove.length && !add.length) return of(results);

        forkJoin(...remove).pipe(
            defaultIfEmpty(null),
        ).subscribe(() => {
            forkJoin(...add).pipe(
                defaultIfEmpty(null),
            ).subscribe(() => {
                subj.next(results);
            });
        });

        return subj.asObservable();
    }

    private loadResident(specialityId?: string, email?: string) {
        this.isReady = false;

        this.app.addLoading(this.service.getResident(email)).subscribe(data => {
            this.residentInfo = data;

            if (data.length) {
                if (specialityId)
                    this.report.Resident = data.filter(t => t.SpecialityId.toLowerCase() == specialityId.toLowerCase())[0] ?? data[0];
                else
                    this.report.Resident = data[0];

                if (!specialityId && data.length > 1) {
                    // Ensures user must manually select speciality.

                    // This is done to not override resident object values.
                    this.report.Resident = {
                        ...this.report.Resident
                    };

                    this.report.Resident.SpecialityId = null;
                    this.report.Resident.SpecialityCode = null;
                    this.report.Resident.SpecialityName = null;
                    this.report.Resident.ProgramId = null;
                }

                if (!this.student) {
                    this.student = <IPersonSearchResultItem>{
                        Email: this.report.Resident.Email,
                        FirstName: this.report.Resident.Name,
                        LastName: this.report.Resident.Surname
                    };

                    store.setStudent(this.student);
                }
            }

            this.isReady = true;
        }, err => {
            this.isReady = true;
        });
    }

    private loadById(id: number) {
        this.app.addLoading(this.service.getById(id)).subscribe(data => {
            this.report = data;

            this.specialityId = this.report.Resident.SpecialityId;

            this.attachments = data.Attachments.map(t => {
                return {
                    id: t.Id,
                    file: createFileStub(t.FileName)
                };
            });

            if (this.isFinal) { 
                this.isReady = true;
            } else {
                this.originalSpecialityId = this.report.Resident.SpecialityId;
                this.originalFrom = this.report.From;
                this.originalTo = this.report.To;
                this.originalNotes = this.report.Notes;
                this.originalManipulations = JSON.stringify(this.report.Manipulations);

                this.loadResident(this.report.Resident.SpecialityId, this.report.Resident.Email);
            }
        });
    }

    private loadSpecialityManipulations(): Observable<ISpecialityManipulation[]> {
        if (this.specialityManipulations.length) {
            return of(this.specialityManipulations);
        }

        return this.service.getSpecialityManipulations(this.report.Resident.ProgramId);
    }

    private loadSkillLevels(): Observable<IManipulationSkillLevel[]> {
        if (this.skillLevels.length) {
            return of(this.skillLevels);
        }

        return this.service.getSkillLevels();
    }

    private hasChanges(): boolean {
        if (!this.report.Resident) return false;

        if ((this.originalSpecialityId && this.originalSpecialityId != this.report.Resident.SpecialityId)
            || this.originalFrom != this.report.From
            || this.originalTo != this.report.To
            || this.originalNotes != this.report.Notes
            || this.originalManipulations != JSON.stringify(this.report.Manipulations))
            return true;

        return false;
    }

    private getManipulationReportEditModel(model: IManipulationReportModel): IManipulationReportEditModel {
        const editModel: IManipulationReportEditModel = {
            Id: model.Id,
            Notes: model.Notes,
            SpecialityId: this.report.Resident.SpecialityId,
            From: model.From,
            To: model.To,
            Manipulations: model.Manipulations.map(t => this.getManipulationEditModel(t))
        };

        return editModel;
    }

    private getManipulationEditModel(model: IManipulationModel): IManipulationEditModel {
        const editModel: IManipulationEditModel = {
            Id: model.Id,
            Count: model.Count,
            MedicalPerson: model.MedicalPerson,
            Notes: model.Notes,
            OtherSpecialityManipulation: model.OtherSpecialityManipulation,
            OtherSpecialityManipulationDescription: model.OtherSpecialityManipulationDescription,
            OtherSpecialityManipulationSkillLevelId: model.OtherSpecialityManipulationSkillLevel?.Id,
            OtherSpecialityManipulationName: model.OtherSpecialityManipulationName,
            OtherWorkplace: model.OtherWorkplace,
            OtherWorkplaceName: model.OtherWorkplaceName,
            SpecialityManipulationId: model.SpecialityManipulation?.Id,
            WorkplaceId: model.Workplace?.Id,
            WorkplaceName: model.Workplace?.Name,
            CompetenceId: model.CompetenceId
        }

        return editModel;
    }

    private validate(): boolean {
        if (!this.report.Resident.SpecialityId) {
            this.app.alert.error(this.app.translate('manipulation_mustSelectSpeciality'));
        }

        if (!this.report.Manipulations.length) {
            this.app.alert.error(this.app.translate('manipulation_manipulationsEmpty'));
            return false;
        }

        if (!this.form.valid) {
            this.app.alert.error(this.app.translate('manipulation_formError'));
            return false;
        }

        if (!this.isDateRangeValid) {
            this.app.alert.error(this.app.translate('manipulation_dateRangeError'));
            return false;
        }

        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].file) {
                for (let j = i + 1; j < this.attachments.length; j++) {
                    if (this.attachments[j] && this.attachments[i].file.name == this.attachments[j].file.name) {
                        this.app.alert.error(this.app.translate('manipulation_fileNamesMustBeUnique'));
                        return false;
                    }
                }
            }
        }

        return true;
    }

    private sortSpecialityManipulations() {
        var propertyName = this.app.currentLanguage.toLowerCase() != 'en'
            ? 'Name'
            : 'NameEn';

        this.specialityManipulations.sort((a, b) => {
            if (a[propertyName] < b[propertyName]) {
                return -1;
            }

            if (a[propertyName] > b[propertyName]) {
                return 1;
            }
            return 0;
        })
    }
}
