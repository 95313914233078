import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IOnCallShiftReportEditModel, IOnCallShiftReport, IOnCallShiftReportListItem, IOnCallShiftResident, IOnCallShiftWorkplace } from '../models/OnCallShift';

import { environment as ENV } from '../../environments/environment';
import { Utils } from '../core/Utils';
import { map } from 'rxjs/operators';

/**
 * On-call shift service
 */
@Injectable({ providedIn: "root" })
export class OnCallShiftService {
    constructor(public http: HttpClient) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/onCallShifts` }

    get(email?: string): Observable<IOnCallShiftReportListItem[]> {
        const params = email ? { email } : {};
        return this.http.get<IOnCallShiftReportListItem[]>(this.apiUrl, { params });
    }

    getById(id: number): Observable<IOnCallShiftReport> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<IOnCallShiftReport>(url);
    }

    getWorkplaces(filter: string): Observable<IOnCallShiftWorkplace[]> {
        const url = `${this.apiUrl}/workplaces`;
        const params = filter ? { filter } : {};
        return this.http.get<IOnCallShiftWorkplace[]>(url, { params });
    }

    getResident(email?: string): Observable<IOnCallShiftResident[]> {
        const url = `${this.apiUrl}/resident`;
        const params = email ? { email } : {};

        return this.http.get<IOnCallShiftResident[]>(url, { params });
    }

    create(model: IOnCallShiftReportEditModel, email?: string): Observable<number> {
        const url = `${this.apiUrl}`;
        const params = email ? { email } : {};

        model.Shifts.forEach(t => {
            t.Date = Utils.getInputDateString(t.Date) as any;
        });

        return this.http.post<number>(url, model, { params });
    }

    update(id: number, model: IOnCallShiftReportEditModel): Observable<number> {
        const url = `${this.apiUrl}/${id}`;

        model.Shifts.forEach(t => {
            t.Date = Utils.getInputDateString(t.Date) as any;
        });

        return this.http.put<number>(url, model);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    submit(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/submit`;
        return this.http.post(url, null);
    }

    getPdf(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/pdf`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }

    addAttachment(id: number, file: File): Observable<number> {
        const url = `${this.apiUrl}/${id}/attachments`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post<number>(url, form);
    }

    removeAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.delete(url).pipe(map(res => res));
    }

    getAttachment(attachmentId: number): Observable<any> {
        const url = `${this.apiUrl}/attachments/${attachmentId}`;
        return this.http.post(url, null, { responseType: 'blob' as 'json' });
    }
}
