export enum ManipulationReportStatus {
    Draft = 'Draft',
    Submitted = 'Submitted'
}

export interface IManipulationReportEditModel {
    Id: number;
    SpecialityId: string;
    Notes: string;
    From?: Date;
    To?: Date;
    Manipulations: IManipulationEditModel[];
}

export interface IManipulationEditModel {
    Id?: number;
    Count?: number;
    MedicalPerson: string;
    Notes: string;
    OtherSpecialityManipulation: boolean;
    OtherSpecialityManipulationDescription: string;
    OtherSpecialityManipulationSkillLevelId: string;
    OtherSpecialityManipulationName: string;
    OtherWorkplace: boolean;
    OtherWorkplaceName: string;
    SpecialityManipulationId: string
    WorkplaceId: string;
    WorkplaceName: string;
    CompetenceId?: string;
}

export interface IManipulationReportModel {
    Id: number;
    From?: Date;
    To?: Date;
    Notes: string;
    FileDate?: Date;
    Status: ManipulationReportStatus;
    Resident: IManipulationResident;
    Manipulations: IManipulationModel[];
    Attachments: { Id: number, FileName: string }[];
}

export interface IManipulationModel {
    Id: number;
    Count?: number;
    MedicalPerson: string;
    Notes: string;
    OtherSpecialityManipulation: boolean;
    OtherSpecialityManipulationDescription: string;
    OtherSpecialityManipulationSkillLevel: IManipulationSkillLevel;
    OtherSpecialityManipulationName: string;
    OtherWorkplace: boolean;
    OtherWorkplaceName: string;
    SpecialityManipulation: ISpecialityManipulation
    Workplace: IManipulationWorkplace;
    Competence?: string;
    CompetenceId?: string;
}

export interface IManipulationReportListItem {
    Id: number;
    Status: ManipulationReportStatus;
    StudyYear: number;
    Speciality: string;
    From?: Date;
    To?: Date;
}

export interface IManipulationWorkplace {
    Id: string;
    Name: string;
}

export interface IManipulationSkillLevel {
    Id: string;
    Name: string;
    Code: string;
}

export interface IManipulationResident {
    StudentId: string;
    PersonId: string;
    PersonCode: string;
    Email: string;
    Name: string;
    Surname: string;
    SpecialityId: string;
    SpecialityCode: string;
    SpecialityName: string;
    ProgramId: string;
    StudyYear: number;
    WorkplaceId?: string;
    WorkplaceName?: string;
}

export interface ISpecialityManipulation {
    Id: string;
    Name: string;
    NameEn: string;
    Code: string;
    SkillLevel: IManipulationSkillLevel;
    SkillAcquirableId: string;
}
